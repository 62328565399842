<template>
  <el-dialog custom-class="ql-dialog" title="请选择学校" :visible.sync="visible" width="480px" @close="handleClose">
    <ul class="school-list">
      <li
        v-for="item in schoolList"
        :key="item.schoolId"
        :class="['truncate cursor-pointer', { 'is-active': item.schoolId === activeSchool.schoolId }]"
        :title="item.schoolName"
        @click="activeSchool = item"
      >
        {{ item.schoolName }}
      </li>
    </ul>
    <qlEmpty type="data" v-show="schoolList.length === 0" />
    <span slot="footer" v-show="schoolList.length">
      <el-button
        type="primary"
        size="small"
        :disabled="!activeSchool.schoolId"
        :loading="isLoading"
        @click="handleSelectLogin"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// components
import { Dialog, Button } from 'element-ui';
import qlEmpty from '@/components/qlEmpty/index';
// api
import { getUserBindSchoolListCloud, loginByWechatValidCloud } from '@/api/common';
// mixins
import loginDispatchMixin from '@/mixins/loginDispatch';
// utils
import { getHostUrlPath } from '@/utils/common';

// 微信扫码
export default {
  mixins: [loginDispatchMixin],
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    qlEmpty,
  },
  data() {
    return {
      visible: true,
      isLoading: false,
      schoolList: [],
      activeSchool: {},
    };
  },
  methods: {
    initSchool() {
      getUserBindSchoolListCloud({
        unionId: this.$route.query.unionId,
      }).then((res) => {
        this.schoolList = res.result;
      });
    },

    handleSelectLogin() {
      this.isLoading = true;
      this.$store
        .dispatch('user/login', {
          userInfo: {
            code: this.$route.query.wechatCode,
            schoolId: this.activeSchool.schoolId,
            schoolHost: getHostUrlPath('/sync-redirect'),
            returnType: 2,
            deviceType: 3,
            productType: 1,
          },
          request: loginByWechatValidCloud,
        })
        .then(() => {
          this.isLoading = false;
          this.checkLicenseInfo(() => {
            this.$emit('enter', this.activeSchool.serverUrl);
          });
        })
        .catch(({ status, result, msg }) => {
          this.isLoading = false;
          this.openLicenseToast({ status, result, msg, isBack: true });
        });
    },

    // 关闭选择学校
    handleClose() {
      this.$emit('back');
    },
  },
  mounted() {
    this.initSchool();
  },
};
</script>

<style lang="scss" scoped>
.school-list {
  li {
    margin-bottom: 12px;
    padding: 9px 32px;
    background-color: $-C8;
    border-radius: 20px;

    &.is-active,
    &:hover {
      background: $-C1;
      color: $-C9;
    }
  }
}
::v-deep {
  .el-dialog__body {
    max-height: 516px;
    padding: 24px 40px 12px;
    overflow: auto;
  }
}
</style>
