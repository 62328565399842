<template>
  <div class="text-center">
    <div class="go-back" @click="$emit('back')">
      <svg class="mt-3px icon svg-icon T4-2" aria-hidden="true">
        <use xlink:href="#icon-fanhui"></use>
      </svg>
      <span class="mg-l-3 T5-2">返回</span>
    </div>

    <h2 class="T1B mg-t-32 mg-b-6">你的微信未绑定学校账号</h2>
    <p class="T4-3 mg-b-32">为了访问学校数据，请先绑定学校账号</p>

    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="schoolName" class="form-item mg-t-17">
        <schoolSelect class="w-full" @select="changeSchool" />
      </el-form-item>

      <el-form-item prop="username" class="form-item">
        <el-input
          class="form-input"
          placeholder="账号/手机号/教职工号"
          v-model.trim="form.username"
          @keyup.native.enter="submit"
        >
          <i slot="prefix" class="iconfont icon-a-yonghuwode"></i>
        </el-input>
      </el-form-item>

      <el-form-item prop="password" class="password-form">
        <el-input
          class="form-input"
          autocomplete="new-password"
          placeholder="密码"
          :type="showPassword ? 'text' : 'password'"
          v-model.trim="form.password"
          @keyup.native.enter="submit"
        >
          <i slot="prefix" class="iconfont icon-mima"></i>
          <i
            slot="suffix"
            :class="'iconfont cursor-pointer text-info ' + (showPassword ? 'icon-xianshi-zheng' : 'icon-yincang_bi')"
            @click="showPassword = !showPassword"
          ></i>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button class="w-full" type="primary" @click="submit">确定</el-button>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from 'element-ui';
import schoolSelect from '@/components/schoolSelect/index';
// api
import { wechatBindUserServer } from '@/api/user';
// utils
import { rsaEncryption } from '@/utils/common';
// mixins
import loginDispatchMixin from '@/mixins/loginDispatch';

const REPETITIVE_BIND_STATUS = 999995; // 该账号已绑定微信（重复绑定）

export default {
  mixins: [loginDispatchMixin],
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Button.name]: Button,
    schoolSelect,
  },
  data() {
    return {
      form: {
        schoolId: '',
        schoolName: '',
        serverUrl: '',
        username: '',
        password: '',
        isRemember: false,
      },
      rules: {
        schoolName: [{ required: true, message: '学校名称不能为空' }],
        username: [{ required: true, message: '用户名不能为空', trigger: 'change' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'change' }],
      },
      showPassword: false,
      tipVisible: false,
    };
  },
  methods: {
    // 学校选择
    changeSchool(data) {
      this.form.schoolId = data.schoolId;
      this.form.schoolName = data.schoolName;
      this.form.serverUrl = data.serverUrl;
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.wechatBindUserRequest(2);
        }
      });
    },

    // 微信绑定请求（replaceBind 是否强制更改绑定: 1-是 2-否）
    wechatBindUserRequest(replaceBind) {
      this.$store
        .dispatch('user/login', {
          userInfo: {
            code: this.$route.query.wechatCode,
            schoolId: this.form.schoolId,
            userName: this.form.username.trim(),
            password: rsaEncryption(this.form.password.trim()),
            deviceType: 3,
            productType: 1,
            replaceBind,
          },
          request: wechatBindUserServer,
        })
        .then(() => {
          this.checkLicenseInfo(() => {
            this.$toast({
              type: 'success',
              title: '绑定成功！',
              content: '${second}秒后进入平台...',
              duration: 3000,
              confirmCallback: () => {
                this.$emit('enter', this.form.serverUrl);
              },
            });
          });
        })
        .catch(({ status, result, msg }) => {
          if (replaceBind === 2 && status === REPETITIVE_BIND_STATUS) {
            this.dealWithRepetitiveBind();
          } else {
            this.openLicenseToast({ status, result, msg: msg || '绑定失败，请检查网络' });
          }
        });
    },

    // 处理当前账号重复绑定
    dealWithRepetitiveBind() {
      const h = this.$createElement;
      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-13' }, '此账号已绑定其他微信 '),
          h('p', { class: 'T5-2' }, '是否继续绑定新微信？绑定新微信后原微信需要重新绑定账号登录。'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: '继续绑定',
        cancelButtonText: '取消',
        confirmButtonClass: 'btn-ghost-danger',
      })
        .then(() => {
          this.wechatBindUserRequest(1);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消绑定',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  position: absolute;
  top: 24px;
  left: 24px;
  cursor: pointer;
}
.form-item {
  margin-bottom: 40px;
}
.password-form {
  margin-bottom: 56px;
}
</style>
