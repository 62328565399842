<template>
  <div class="login" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)">
    <div class="login-header flex justify-between pd-t-24 pd-l-40 pd-r-32">
      <div class="flex items-center">
        <img class="login-logo mg-r-8" src="@images/components/login/logo.png" />
        <span class="T3B">{{ platformName }}</span>
      </div>
    </div>

    <!-- 绑定了多个学校，需要选择学校 -->
    <selectBindSchool v-if="status === WECHAT_BIND_MULTIPLE_SCHOOL" @enter="wechatLogin" @back="handleBack" />
    <!-- 未绑定，需要绑定并登录 -->
    <div class="login-box relative" v-if="status === WECHAT_NOBIND_ERR">
      <bindWechat @enter="wechatLogin" @back="handleBack" />
    </div>

    <copyrightHtml />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// components
import selectBindSchool from './components/selectBindSchool';
import bindWechat from './components/bindWechat';
import copyrightHtml from '@/components/copyrightHtml/index';
// mixins
import loginDispatchMixin from '@/mixins/loginDispatch';
// utils
import { getHostUrlPath } from '@/utils/common';

const WECHAT_BIND_MULTIPLE_SCHOOL = 999997; // 该微信在多个学校绑定了账号
const WECHAT_NOBIND_ERR = 999998; // 找不到微信绑定关系
const WECHAT_BIND_ERR = 999999; // 微信绑定异常

export default {
  mixins: [loginDispatchMixin],
  components: {
    selectBindSchool,
    bindWechat,
    copyrightHtml,
  },
  data() {
    return {
      isLoading: true,
      status: 0,

      WECHAT_BIND_MULTIPLE_SCHOOL,
      WECHAT_NOBIND_ERR,
    };
  },
  computed: {
    ...mapState({
      platformName: (state) => state.common.platformName,
    }),
  },
  methods: {
    init() {
      const routeQuery = this.$route.query;
      const { token, status = 0, msg, beginDate = '' } = routeQuery;

      // 登录成功
      if (Object.prototype.hasOwnProperty.call(routeQuery, 'token')) {
        this.$store.commit('user/SET_TOKEN', token);
        this.checkLicenseInfo(() => {
          this.loginDispatch();
        });
      }

      // 不能直接登录
      else if (Object.prototype.hasOwnProperty.call(routeQuery, 'status')) {
        this.status = Number(status);
        switch (this.status) {
          case WECHAT_BIND_MULTIPLE_SCHOOL:
            // 绑定了多个学校，需要选择学校
            this.isLoading = false;
            break;
          case WECHAT_NOBIND_ERR:
            // 未绑定，跳转到绑定页面
            this.isLoading = false;
            break;
          case WECHAT_BIND_ERR:
            // 微信绑定异常
            this.$message.error('登录失败，请刷新二维码重试');
            window.history.go(-1);
            break;
          default:
            // 未激活（授权未生效、已过期）
            this.isLoading = false;
            this.openLicenseToast({ status: this.status, result: { beginDate }, msg });
            break;
        }
      }
    },

    // 登录跳转中转页
    wechatLogin(serverUrl) {
      if (!serverUrl) return;
      window.location.replace(
        getHostUrlPath(`/sync-redirect?token=${this.$store.getters.token}&isLogin=true`, serverUrl)
      );
    },

    handleBack() {
      // this.$router.replace('login');
      window.history.go(-1);
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
